import React, { FC, Fragment, useMemo } from 'react';
import { InputText, RadioButton, Select } from 'components';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import ApplicationFormFooter from '../ApplicationFormFooter/ApplicationFormFooter';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useFormFieldErrorNotification } from 'helpers';
import { setApplicationLicenceCode, setApplicationProductCode, setApplicationComponentId, setApplicationYear, setApplicationPrice } from 'store';
import { PatternFormat } from 'react-number-format';
import {
  LICENSE_CODE_PATTERN,
  LICENSE_CODE_FORMAT,
  PRODUCT_CODE_PATTERN,
  PRODUCT_CODE_FORMAT,
  MASK_SYMBOL,
} from 'consts';
import './QuickbooksClientLicense.scss';

interface QuickbooksClientLicenseForm {
  componentId: number;
  year: string;
  licenceCode: string;
  productCode: string;
}

interface Props {
  onCancel: () => void;
  changeCurrentStep: React.Dispatch<React.SetStateAction<any>>;
}

const QuickbooksClientLicense: FC<Props> = ({ onCancel, changeCurrentStep }) => {
  const quickbooksProducts = useAppSelector(state => state.products.adminQuickbooksProducts || []);
  const application = useAppSelector(state => state.products.newApplication);
  const { isLoading } = useAppSelector(state => state.products.getProductPriceRequest);
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState,
    formState: { errors, isValid, touchedFields },
  } = useForm<QuickbooksClientLicenseForm>({
    mode: 'all',
    defaultValues: {
      componentId: application.componentId,
      year: application.year,
      licenceCode: application.licenceCode,
      productCode: application.productCode,
    },
  });
  useFormFieldErrorNotification(formState, ['year', 'licenceCode', 'productCode']);

  const componentId = useMemo(() => getValues('componentId'), [watch()]);

  // const handleGetProductPrice = async (componentId: number) => {
  //   const { price }: { price: string } = await dispatch(getProductPrice(componentId)).unwrap();
  //   dispatch(setApplicationPrice(price));
  // };

  const onSubmit: SubmitHandler<QuickbooksClientLicenseForm> = (data) => {
    // handleGetProductPrice(data.componentId);
    dispatch(setApplicationComponentId(data.componentId));
    dispatch(setApplicationYear(data.year));
    dispatch(setApplicationLicenceCode(data.licenceCode));
    dispatch(setApplicationProductCode(data.productCode));
    changeCurrentStep(2);
  };

  return (
    <form className="quickbooks-client-license" onSubmit={handleSubmit(onSubmit)}>
      <div className="quickbooks-client-license__title">
        Which version of QuickBooks do you currently own?
      </div>
      {quickbooksProducts.map((item: any) => (
        <Fragment key={item.id}>
          <Controller
            name="componentId"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <RadioButton
                value={value}
                isChecked={componentId === item.componentId}
                onChange={() => {
                  onChange(item.componentId);
                  dispatch(setApplicationPrice(''));
                  setValue('year', '');
                  setValue('licenceCode', '');
                  setValue('productCode', '');
                }}
                label={item.version}
              />
            )}
          />
          {componentId === item.componentId && (
            <div className="quickbooks-client-license__info">
              <Controller
                name="year"
                rules={{ required: true }}
                control={control}
                defaultValue={application.year}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    classes="rel-m-b-16"
                    value={value}
                    onClose={onBlur}
                    onChange={onChange}
                    label="Choose year"
                    isRequired
                    placeholder="Please choose year"
                    options={item.years.map(String)}
                    isInvalid={!!(errors.year && touchedFields.year)}
                  />
                )}
              />
              <Controller
                name="licenceCode"
                control={control}
                defaultValue=""
                rules={{ pattern: LICENSE_CODE_PATTERN }}
                shouldUnregister
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { invalid, isTouched },
                }) => (
                  <PatternFormat
                    format={LICENSE_CODE_FORMAT}
                    mask={MASK_SYMBOL}
                    customInput={InputText}
                    classes="rel-m-b-24"
                    label="License сode"
                    placeholder="0000-0000-0000-000"
                    value={value}
                    isInvalid={isTouched && invalid}
                    isReturnEvent
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
              <Controller
                name="productCode"
                control={control}
                defaultValue=""
                rules={{ pattern: PRODUCT_CODE_PATTERN }}
                shouldUnregister
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { invalid, isTouched },
                }) => (
                  <PatternFormat
                    format={PRODUCT_CODE_FORMAT}
                    mask={MASK_SYMBOL}
                    customInput={InputText}
                    classes="rel-m-b-32"
                    label="Product сode"
                    placeholder="000-000"
                    value={value}
                    isInvalid={isTouched && invalid}
                    isReturnEvent
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>
          )}
        </Fragment>
      ))}
      <ApplicationFormFooter
        onCancel={onCancel}
        submitForm
        isOkDisabled={!isValid}
        isOkLoading={isLoading}
        cancelText="Back"
        okText="Next"
      />
    </form>
  );
};

export default QuickbooksClientLicense;
