import './Country.scss';
import { FC, useRef, useState, useMemo, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useFormFieldErrorNotification, useSimpleFormNavigate } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  createUser,
  createOrder,
  getProductsInfo,
  getOtherProductsInfo,
  resetOrder,
  setCompanyName,
  setOrderId,
  setCompanyInfoId,
  setCountry,
  setUserDetails,
  getAccessToken,
  getCountries,
  getCountryStates,
  setOtherProducts,
  getSubscriptionTerms,
} from 'store';

import { UsaNewIcon, CanadaNewIcon, CountryNoFlagIcon } from 'assets/svg';

import {
  CANADA_PROVINCE_LABELS,
  EMAIL_PATTERN,
  POSTAL_CODE_PATTERN,
  PHONE_PATTERN_SIMPLE,
  USA_STATES_LABELS,
  ZIP_CODE_PATTERN,
  ERROR_MESSAGES_BY_SPECIFIC_CODE,
} from 'consts';
import {
  CanadaProvince,
  CountryCode,
  OrderNavigation,
  USAStates,
  AccessTokenType,
  RefreshTokenType,
  SpecificCode,
} from 'enums';
import { ICompanyInfo, ICountry, ICountryState, IOtherProduct } from 'interfaces';

import {
  RadioButton,
  InputText,
  Navigation,
  OrderNextButton,
  Logo,
  Select,
} from 'components';

interface ICreateUserDetailsRes {
  id: string;
  companyInfoId: string;
}

type CountryForm = {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  companyDomainName: string;
  companyInfo: ICompanyInfo;
};

const COUNTRY_NAMES_BY_CODE = {
  [CountryCode.USA]: 'Usa',
  [CountryCode.Canada]: 'Canada',
  [CountryCode.Other]: 'Other',
};

const PROVINCE_FIELD_DATA = {
  [CountryCode.USA]: {
    label: 'State',
    placeholder: 'Select state',
    options: Object.values(USAStates).map(value => ({
      label: USA_STATES_LABELS[value],
      value,
    })),
  },
  [CountryCode.Canada]: {
    label: 'Province',
    placeholder: 'Select province',
    options: Object.values(CanadaProvince).map(value => ({
      label: CANADA_PROVINCE_LABELS[value],
      value,
    })),
  },
  [CountryCode.Other]: {
    label: 'State',
    placeholder: 'Select state',
    options: Object.values(USAStates).map(value => ({
      label: USA_STATES_LABELS[value],
      value,
    })),
  },
};

const POSTAL_CODE_FIELD_DATA = {
  [CountryCode.USA]: {
    label: 'Zip code',
    placeholder: 'Enter zip code',
  },
  [CountryCode.Canada]: {
    label: 'Postal code',
    placeholder: 'Enter postal code',
  },
  [CountryCode.Other]: {
    label: 'Zip code',
    placeholder: 'Enter zip code',
  },
};

const Country: FC = () => {
  const [currentCountryInit, setCurrentCountryInit] = useState<CountryCode>(CountryCode.USA);
  const { data: otherCountries = []}: { data?: ICountry[] } = useAppSelector(
    state => state.country.getCountriesRequest || {},
  );
  const {
    data: otherCountryStates = [],
    isLoading: isLoadingOtherCountryStates = false,
  }: { data?: ICountryState[]; isLoading: boolean } = useAppSelector(
    state => state.country.getCountryStatesRequest || {},
  );
  const schema = useMemo(() => yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().required('Email is required')
      .matches(EMAIL_PATTERN, 'Please enter a valid email'),
    companyName: yup.string().required('Company name is required'),
    companyDomainName: yup.string().required('Corporate email address is required')
      .matches(EMAIL_PATTERN, 'Please enter a valid corporate email address'),
    companyInfo: yup.object({
      phoneNumber: yup.string().required('Phone number is required')
        .matches(PHONE_PATTERN_SIMPLE, 'Please enter a valid phone')
        .max(15, 'Phone number can\'t be longer than 15 digits'),
      address1: yup.string().required('Address is required'),
      address2: yup.string(),
      city: yup.string().required('City is required'),
      postalCode: yup.lazy(() => {
        if (currentCountryInit === CountryCode.Canada) {
          return yup.string().required('Postal code is required')
            .matches(POSTAL_CODE_PATTERN, 'Please enter a valid postal code');
        }
        if (currentCountryInit === CountryCode.USA) {
          return yup.string().required('Zip code is required')
            .matches(ZIP_CODE_PATTERN, 'Please enter a valid zip code')
            .min(5, 'Must be exactly 5 digits')
            .max(5, 'Must be exactly 5 digits');
        }
        return yup.string().required('Zip code is required');
      }),
      ...(currentCountryInit === CountryCode.Other ? {
        otherCountryCode: yup.string().required('Country is required'),
      } : {}),
      province: yup.lazy(() => {
        if (currentCountryInit !== CountryCode.Other || isLoadingOtherCountryStates || otherCountryStates.length > 0) {
          return yup.string().required(
            `${PROVINCE_FIELD_DATA[currentCountryInit].label} is required`,
          );
        }

        return yup.string();
      }),
    }),
  }), [currentCountryInit, otherCountryStates, isLoadingOtherCountryStates]);

  const {
    control,
    getValues,
    formState,
    handleSubmit,
    register,
    resetField,
    setError,
    watch,
  } = useForm<CountryForm>({
    mode: 'all', resolver: yupResolver(schema),
  });
  const { errors, isValid, touchedFields } = formState;
  useFormFieldErrorNotification(formState, ['email', 'companyDomainName', 'companyInfo.postalCode']);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isNextLoading, setIsNextLoading] = useState<boolean>(false);
  const companyNameInputRef = useRef<HTMLInputElement | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCountries()).unwrap();
  }, []);
  const fetchCountryStates = async (countryCode: string): Promise<void> => {
    await dispatch(getCountryStates({ countryCode })).unwrap();
    resetField('companyInfo.province', { keepDirty: false, keepTouched: false, keepError: false });
  };

  const resetFieldsWithDiferentOptions = () => {
    resetField('companyInfo.province', { keepDirty: false, keepTouched: false, keepError: false });
    resetField('companyInfo.postalCode', { keepDirty: false, keepTouched: false, keepError: false });
  };
  const isDisablePovinceField = useMemo(
    () => currentCountryInit === CountryCode.Other
      && (!getValues('companyInfo.otherCountryCode') || isLoadingOtherCountryStates || otherCountryStates.length <= 0),
    [currentCountryInit, watch('companyInfo.otherCountryCode'), isLoadingOtherCountryStates, otherCountryStates],
  );

  const simpleFormNavigate = useSimpleFormNavigate();
  const onNextHandler: SubmitHandler<CountryForm> = async (data) => {
    setIsNextLoading(true);
    try {
      const token = !!executeRecaptcha && await executeRecaptcha('countryPage');
      const { firstName, lastName, email, companyName, companyDomainName, companyInfo } = data;
      const country: CountryCode = currentCountryInit === CountryCode.Other
        ? (companyInfo.otherCountryCode as CountryCode) || CountryCode.USA
        : currentCountryInit;
      const userData = {
        email: email.trim(),
        captcha_response: token,
        country,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        company_name: companyName.trim(),
        company_domain_name: companyDomainName.trim(),
        company_info: {
          phone_number: companyInfo.phoneNumber.trim(),
          address_1: companyInfo.address1.trim(),
          address_2: companyInfo.address2.trim(),
          city: companyInfo.city.trim(),
          postal_code: companyInfo.postalCode,
          province: companyInfo.province,
        },
      };
      dispatch(resetOrder());

      dispatch(setCountry({
        code: country,
        name: currentCountryInit === CountryCode.Other
          ? (otherCountries.find(({ code }) => code === country) ?? {}).name || ''
          : COUNTRY_NAMES_BY_CODE[currentCountryInit],
      }));
      const {
        id: userId,
        companyInfoId,
      }: ICreateUserDetailsRes = await dispatch(createUser(userData)).unwrap();

      // get access token and save to session storage
      const tokenResponse: {
        access_token: string;
        refresh_token: string;
      } = await dispatch(getAccessToken(email)).unwrap();
      if (tokenResponse && tokenResponse.access_token) {
        sessionStorage.setItem(AccessTokenType.AccessTokenSf, tokenResponse.access_token);
      }
      if (tokenResponse && tokenResponse.refresh_token) {
        sessionStorage.setItem(RefreshTokenType.RefreshTokenSf, tokenResponse.refresh_token);
      }

      dispatch(setCompanyInfoId(companyInfoId));
      const countryProducts = await dispatch(getProductsInfo(country)).unwrap();
      const countryProductsNames = (countryProducts?.products || []).map(({ name }: { name: string }) => name);
      const otherProducts = await dispatch(getOtherProductsInfo()).unwrap();
      dispatch(setOtherProducts((otherProducts as { id: string; version: string; price: number }[]).reduce(
        (productsList, { id, version: name, price }) => {
          if (!countryProductsNames.includes(name)) {
            productsList.push({ id, name, price, isChecked: false, quantity: '' });
          }

          return productsList;
        },
        [] as IOtherProduct[],
      )));
      await dispatch(getSubscriptionTerms()).unwrap();
      const orderId: string = await dispatch(createOrder({
        country,
        userId,
      })).unwrap();
      dispatch(setCompanyName(companyName));
      dispatch(setOrderId(orderId));
      dispatch(setUserDetails({
        id: userId,
        ...data,
      }));

      simpleFormNavigate(OrderNavigation.Products);
    } catch (e: any) {
      if (e.message === ERROR_MESSAGES_BY_SPECIFIC_CODE[SpecificCode.AlreadyRegistered]) {
        setError('email', { type: 'already-exists' });
      }
    } finally {
      setIsNextLoading(false);
    }
  };
  return (
    <>
      <Logo />
      <div className="country page">
        <div className="page-left">
          <UsaNewIcon className={currentCountryInit === CountryCode.USA ? 'active' : ''} />
          <CanadaNewIcon className={currentCountryInit === CountryCode.Canada ? 'active' : ''} style={{ marginTop: '-0.13vh' }} />
          <CountryNoFlagIcon className={currentCountryInit === CountryCode.Other ? 'active' : ''} />
        </div>
        <div className="page-right">
          <Navigation currentStep={1} hideCountry />

          <div className="page-right_content">
            <h1>Welcome</h1>

            <div className="country_location">
              <h4>Please choose your location</h4>
              {[
                CountryCode.Canada,
                CountryCode.USA,
                CountryCode.Other,
              ].map(countryCode => (
                <RadioButton
                  isChecked={currentCountryInit === countryCode}
                  label={COUNTRY_NAMES_BY_CODE[countryCode].toUpperCase()}
                  value={countryCode}
                  key={countryCode}
                  onChange={() => {
                    resetFieldsWithDiferentOptions();
                    setCurrentCountryInit(countryCode);
                  }}
                />
              ))}
            </div>
            <form>
              <div className="row-items rel-m-b-20">
                <InputText
                  label="First name"
                  placeholder="e.g. Tom"
                  name="firstName"
                  register={register}
                  isRequired
                  isInvalid={!!errors.firstName}
                />
                <InputText
                  label="Last name"
                  placeholder="e.g. Riddle"
                  name="lastName"
                  register={register}
                  isRequired
                  isInvalid={!!errors.lastName}
                />
              </div>
              <InputText
                type="email"
                classes="rel-m-b-20"
                label="Enter your email"
                placeholder="Enter your email"
                name="email"
                register={register}
                isRequired
                isInvalid={!!(errors.email && touchedFields.email)}
              />
              <Controller
                name="companyName"
                control={control}
                defaultValue=""
                shouldUnregister
                render={({
                  field: { ref, onChange, onBlur, value },
                  fieldState: { invalid },
                }) => (
                  <InputText
                    // inpurRef={(e) => {
                    //   ref(e);
                    //   companyNameInputRef.current = e;
                    // }}
                    classes="rel-m-b-20"
                    label="Company name"
                    placeholder="Enter client company name"
                    value={value}
                    isRequired
                    isInvalid={invalid}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={() => {
                      if (companyNameInputRef.current !== null) {
                        companyNameInputRef.current?.scrollIntoView({
                          block: 'center',
                          behavior: 'smooth',
                        });
                      }
                    }}
                  />
                )}
              />
              <InputText
                classes="rel-m-b-20"
                label="Corporate email address"
                placeholder="Enter company domain email"
                name="companyDomainName"
                register={register}
                infoContent="Please provide email address you are using for work within your organisation"
                isRequired
                isInvalid={!!(errors.companyDomainName && touchedFields.companyDomainName)}
              />
              <InputText
                classes="rel-m-b-56"
                label="Company phone number"
                placeholder="+1 (000) 000-0000"
                name="companyInfo.phoneNumber"
                register={register}
                isRequired
                isInvalid={!!errors.companyInfo?.phoneNumber}
              />
              <h3 className="rel-m-b-20">Company address</h3>
              <InputText
                classes="rel-m-b-20"
                label="Address 1"
                placeholder="Enter first address"
                name="companyInfo.address1"
                register={register}
                isRequired
                isInvalid={!!errors.companyInfo?.address1}
              />
              <InputText
                classes="rel-m-b-20"
                label="Address 2"
                placeholder="Enter second address"
                name="companyInfo.address2"
                register={register}
                isInvalid={!!errors.companyInfo?.address2}
              />
              <div className="row-items rel-m-b-20">
                <InputText
                  label="City"
                  placeholder="Enter city"
                  name="companyInfo.city"
                  register={register}
                  isRequired
                  isInvalid={!!errors.companyInfo?.city}
                />
                <InputText
                  label={POSTAL_CODE_FIELD_DATA[currentCountryInit].label}
                  placeholder={POSTAL_CODE_FIELD_DATA[currentCountryInit].placeholder}
                  name="companyInfo.postalCode"
                  register={register}
                  isRequired
                  isInvalid={!!(errors.companyInfo?.postalCode && touchedFields.companyInfo?.postalCode)}
                />
              </div>
              {currentCountryInit === CountryCode.Other && (
                <Controller
                  name="companyInfo.otherCountryCode"
                  control={control}
                  defaultValue=""
                  shouldUnregister
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { invalid },
                  }) => (
                    <Select
                      value={value}
                      classes="rel-m-b-20"
                      label="Country"
                      placeholder="Select country"
                      options={otherCountries.map(({ code, name }) => ({ value: code, label: name }))}
                      isInvalid={invalid}
                      isRequired
                      onChange={(value: string): void => {
                        fetchCountryStates(value);
                        onChange(value);
                      }}
                      onClose={onBlur}
                    />
                  )}
                />
              )}
              <Controller
                name="companyInfo.province"
                control={control}
                defaultValue=""
                shouldUnregister
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { invalid },
                }) => (
                  <Select
                    value={value}
                    classes="rel-m-b-32"
                    label={PROVINCE_FIELD_DATA[currentCountryInit].label}
                    placeholder={PROVINCE_FIELD_DATA[currentCountryInit].placeholder}
                    options={currentCountryInit === CountryCode.Other
                      ? otherCountryStates.map(({ code, name }) => ({ value: code, label: name }))
                      : PROVINCE_FIELD_DATA[currentCountryInit].options}
                    isInvalid={invalid}
                    isRequired
                    isDisabled={isDisablePovinceField}
                    onChange={onChange}
                    onClose={onBlur}
                  />
                )}
              />
            </form>
          </div>
          <OrderNextButton
            isDisabled={!isValid}
            isLoading={isNextLoading}
            onClick={handleSubmit(onNextHandler)}
          />
        </div>
      </div>
    </>
  );
};

export default Country;