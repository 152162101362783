import { Button, Checkbox, Modal, ProgressBar, SignUpBackButton, StepPriceDetails } from 'components';
import './ProductsList.scss';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ArrowExternalLinkIcon, CircleLineWrapper, CloseIcon, MsOfficeLogo, QuickbooksLogo, SageLogo, ThreeCubesIcon } from 'assets/svg';
import QuickbooksChoose from './components/QuickbooksChoose/QuickbooksChoose';
import SageChoose from './components/SageChoose/SageChoose';
import MsOfficeChoose from './components/MsOfficeChoose/MsOfficeChoose';
import OtherProductsChoose from './components/OtherProductsChoose/OtherProductsChoose';
import { MsOfficeLicenseType, ProductChargify, SignUpNavigation } from 'enums';
import { useSignUpFormNavigate } from 'helpers';
import { PRODUCT_CHARGIFY_TITLES } from 'consts';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import * as _ from 'lodash';
import { addProductsToOrder, deleteProductsFromOrder, setMsOfficeLicenseType, setMsOfficeProducts, setOtherProducts, setQuickbooks, setSage } from 'store';

enum ScreenType {
  List = 'list',
  Quickbooks = 'quickbooks',
  Sage = 'sage',
  MsOffice = 'msoffice',
  Other = 'other'
}

interface IQuickbooksForm {
  name: string;
  quantity: string;
  year?: string;
  licenseCode?: string;
  productCode?: string;
}

export interface ISageForm {
  name: string;
  year: string;
  quantity: string;
  serialNumber?: string;
}

const ProductsList: FC = () => {
  const dispatch = useAppDispatch();
  const [isNextLoading, setIsNextLoading] = useState<boolean>(false);
  const [currentScreen, setCurrentScreen] = useState(ScreenType.List);
  const quickbooks = useAppSelector(state => state.order.quickbooks);
  const [quickbooksProduct, setQuickbooksProduct] = useState<IQuickbooksForm | null>(quickbooks.data.name ? quickbooks.data : null as any);
  const sage = useAppSelector(state => state.order.sage);
  const [sageProduct, setSageProduct] = useState<ISageForm | null>(sage.name ? sage : null as any);
  const msOfficeState = useAppSelector(state => state.order.msOffice);
  const [msProductsCopy, setMsProductsCopy] = useState(msOfficeState.products);
  const [isShowMsReviewModal, setShowMsReviewModal] = useState<boolean>(false);
  const [isMsChoiceReviewed, setIsMsChoiceReviewed] = useState<boolean>(false);
  const [isMsOwnConfirmed, setIsMsOwnConfirmed] = useState<boolean>(msOfficeState.licenseType === MsOfficeLicenseType.OwnLicense);
  const hostTotalQuantity = useAppSelector(state => state.order.hostProduct.quantity);
  const otherProducts = useAppSelector(state => state.order.otherProducts);
  const [otherProductsCopy, setOtherProductsCopy] = useState(_.cloneDeep(otherProducts));

  // Quickbooks
  const handleQuickbookAdd = (data: IQuickbooksForm) => {
    setQuickbooksProduct(data);
    setCurrentScreen(ScreenType.List);
  };

  const removeQuickbooks = () => {
    setQuickbooksProduct(null);
  };

  const onRemoveCheckedIconQuickbooksKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      removeQuickbooks();
    }
  };

  // Sage
  const handleSageAdd = (data: ISageForm) => {
    setSageProduct(data);
    setCurrentScreen(ScreenType.List);
  };

  const removeSage = () => {
    setSageProduct(null);
  };

  const onRemoveCheckedIconSageKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      removeSage();
    }
  };

  //Ms Office

  const checkedMsProductsCopy = useMemo(() => {
    return msProductsCopy.filter(el => el.isChecked);
  }, [msProductsCopy]);

  const confirmMsOfficeOwn = () => {
    setIsMsOwnConfirmed(true);
    setShowMsReviewModal(false);
  };

  useEffect(() => {
    if (!isShowMsReviewModal) {
      setIsMsChoiceReviewed(false);
    }
  }, [isShowMsReviewModal]);

  const handleMsProductsAdd = (data: any) => {
    setMsProductsCopy(data);
    setCurrentScreen(ScreenType.List);
  };

  const unCheckMsProduct = useCallback((name) => {
    const currentProductDataIndex = msProductsCopy.findIndex(el => el.name === name);
    if (currentProductDataIndex === -1) {
      return;
    }
    const copyProducts = _.cloneDeep(msProductsCopy);
    copyProducts[currentProductDataIndex] = {
      ...msProductsCopy[currentProductDataIndex],
      isChecked: false,
      quantity: "",
    };
    setMsProductsCopy(copyProducts);
  }, [msProductsCopy]);

  const onRemoveCheckedIconMsKeyDown = (event: any, name: string) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      unCheckMsProduct(name);
    }
  };


  // Other Products

  const checkedOtherProductsCopy = useMemo(() => {
    return otherProductsCopy.filter(el => el.isChecked);
  }, [otherProductsCopy]);

  const unCheckOtherProduct = useCallback((id) => {
    const currentProductDataIndex = otherProductsCopy.findIndex(el => el.id === id);
    if (currentProductDataIndex === -1) {
      return;
    }
    const copyProducts = _.cloneDeep(otherProductsCopy);
    copyProducts[currentProductDataIndex] = {
      ...otherProductsCopy[currentProductDataIndex],
      isChecked: false,
      quantity: "",
      serialNumber: "",
      productCode: "",
      licenseCode: "",
    };
    setOtherProductsCopy(copyProducts);
  }, [otherProductsCopy]);

  const onRemoveCheckedIconOtherKeyDown = (event: any, id: string) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      unCheckOtherProduct(id);
    }
  };

  const handleOtherProductsAdd = (data: any) => {
    setOtherProductsCopy(data);
    setCurrentScreen(ScreenType.List);
  };

  const simpleFormNavigate = useSignUpFormNavigate();

  const changeScreen = (screenName: ScreenType) => {
    setCurrentScreen(screenName);
  };

  const backHandler = () => {
    if (currentScreen == ScreenType.List) {
      simpleFormNavigate(SignUpNavigation.ServerUserHosting);
    } else {
      setCurrentScreen(ScreenType.List);
    }
  };

  const downloadArticle = () => {
    const DOWNLOAD_URL = 'https://ticket.summithosting.com/hc/en-us/articles/4414878635031-Which-Version-Of-Office-Do-I-Need-';
    window.open(DOWNLOAD_URL, '_blank');
  };

  const isNextDisabled = useMemo(() => {
    return checkedMsProductsCopy.length === 0
      && checkedOtherProductsCopy.length === 0
      && !quickbooksProduct
      && !isMsOwnConfirmed
      && !sageProduct;
  }, [checkedMsProductsCopy, checkedOtherProductsCopy, quickbooksProduct, isMsOwnConfirmed, sageProduct]);

  const nextHandler = () => {
    let productsToDelete = [];
    let productsToAdd = [];
    setIsNextLoading(true);
    try {
      // QUICKBOOKS

      // delete quickbooks
      if (quickbooks.data.name !== null && quickbooks.data.name !== quickbooksProduct?.name) {
        productsToDelete.push(quickbooks.data.name);
      }

      //add quickbooks
      if (quickbooksProduct) {
        const { name: quickbookName, quantity: quickbookQuantity, ...restQuickbooksData } = quickbooksProduct;
        const quickbookProductAddData = {
          name: quickbookName,
          quantity: Number(quickbookQuantity),
          customInfo: {
            ...restQuickbooksData,
            provideMetafields: 'true',
          },
        };
        productsToAdd.push(quickbookProductAddData);
        dispatch(setQuickbooks({
          data: {
            name: quickbookName as ProductChargify,
            quantity: `${Number(quickbookQuantity)}`,
            ...restQuickbooksData,
          },
        }));
      } else {
        dispatch(setQuickbooks({
          data: {
            name: null,
            quantity: '',
          },
        }));
      }

      // SAGE

      // remove sage
      if (sage.name !== null && sage.name !== sageProduct?.name) {
        productsToDelete.push(sage.name);
      }

      // add sage
      if (sageProduct) {
        const { name: sageName, quantity: sageQuantity, ...restSageData } = sageProduct;
        const sageProductAddData = {
          name: sageName,
          quantity: Number(sageQuantity),
          customInfo: {
            ...restSageData,
            provideMetafields: 'true',
          },
        };
        productsToAdd.push(sageProductAddData);
        dispatch(setSage({
          name: sageName as ProductChargify,
          quantity: `${Number(sageQuantity)}`,
          ...restSageData,
        } as any));
      } else {
        dispatch(setSage({
          name: null,
          quantity: '',
        } as any));
      }

      // MS OWN
    
      if (isMsOwnConfirmed) {
        productsToAdd.push({
          name: ProductChargify.MSOfficeOwn,
          quantity: hostTotalQuantity,
          customInfo: {
            provideMetafields: 'true',
          },
        });
        dispatch(setMsOfficeLicenseType(MsOfficeLicenseType.OwnLicense));
      } else {
        productsToDelete.push(ProductChargify.MSOfficeOwn);
        dispatch(setMsOfficeLicenseType(null));
      }

      // MS LEASE

      // add ms lease
      const msProductsToAdd = checkedMsProductsCopy.map(({ name, quantity }) => ({
        name,
        quantity: Number(quantity),
        customInfo: {
          provideMetafields: 'true',
        },
      }));

      if (msProductsToAdd.length) {
        dispatch(setMsOfficeLicenseType(MsOfficeLicenseType.LeaseLicense));
      } 

      productsToAdd = productsToAdd.concat(msProductsToAdd);

      // delete ms lease
      const msProductsToDelete = msOfficeState.products.reduce((acc, cur) => {
        if (cur.isChecked && !msProductsToAdd.some(newEl => newEl.name === cur.name)) {
          acc.push(cur.name);
        }
        return acc;
      }, [] as string[]);

      

      msProductsToDelete.forEach((p) => {
        productsToDelete.push(p);
      });

      dispatch(setMsOfficeProducts(msProductsCopy.map(({ quantity, ...restData }) => ({
        ...restData,
        quantity: `${Number(quantity)}`,
      }))));

      // OTHER PRODUCTS

      //add other
      const otherProductsToAdd = checkedOtherProductsCopy.map(({ name, quantity, serialNumber, productCode, licenseCode }) => ({
        name,
        quantity: Number(quantity),
        customInfo: {
          serialNumber,
          productCode,
          licenseCode,
          provideMetafields: 'true',
        },
      }));

      productsToAdd = productsToAdd.concat(otherProductsToAdd);

      // remove other products
      const otherProductsToDelete = otherProducts.reduce((acc, cur) => {
        if (cur.isChecked && !otherProductsToAdd.some(newEl => newEl.name === cur.name)) {
          acc.push(cur.name);
        }
        return acc;
      }, [] as string[]);

      otherProductsToDelete.forEach((p) => {
        productsToDelete.push(p);
      });

      dispatch(setOtherProducts(otherProductsCopy.map(({ quantity, isChecked, ...restData }) => ({
        ...restData,
        isChecked,
        quantity: isChecked && quantity ? `${Number(quantity)}` : '',
      }))));

      // DELETE
      // await dispatch(deleteProductsFromOrder(productsToDelete)).unwrap();

      // ADD
      // await dispatch(addProductsToOrder(productsToAdd)).unwrap();
      // check session
      simpleFormNavigate(SignUpNavigation.AdditionalServices);
    } catch (e: any) {
      //error
    } finally {
      setIsNextLoading(false);
    }
  };

  // STEP PRICE CANCULATION

  const stepProductsByCategories = useMemo(() => {
    return {
      quickbooks: quickbooksProduct ? [{ name: quickbooksProduct.name, quantity: quickbooksProduct.quantity }] : [],
      sage: sageProduct ? [{ name: sageProduct.name, quantity: sageProduct.quantity }] : [],
      msOwn: isMsOwnConfirmed ? [{ name: ProductChargify.MSOfficeOwn, quantity: hostTotalQuantity }] : [],
      msLease: checkedMsProductsCopy.map(({ name, quantity }) => ({ name, quantity })),
      other: checkedOtherProductsCopy.map(({ name, quantity, price }) => ({ name, quantity, price })),
    };
  }, [quickbooksProduct, sageProduct, isMsOwnConfirmed, checkedMsProductsCopy, checkedOtherProductsCopy]);

  const STEP_PRODUCTS = useMemo(() => {
    return [
      ...stepProductsByCategories.quickbooks,
      ...stepProductsByCategories.sage,
      ...stepProductsByCategories.msOwn,
      ...stepProductsByCategories.msLease,
      ...stepProductsByCategories.other,
    ];
  }, [stepProductsByCategories]);

  return (
    <>
      <Modal
        xVersion
        absoluteStyles
        title={null}
        visible={isShowMsReviewModal}
        onCancel={() => setShowMsReviewModal(false)}
        onOk={confirmMsOfficeOwn}
        isOkDisabled={!isMsChoiceReviewed}
        okText="Confirm"
      >
        <div className="ms-choice-confirm-modal-content">
          <div className="ms-choice-title m-b-8">Office 365</div>
          <div className="ms-choice-description-2 m-b-24">The only versions of Office 365 that are allowed on a terminal server are Apps for Enterprise, E3, E5 or Business Premium. If you are not sure which version of Office 365 you currently own, <a className="link font-weight-400 ms-choice-link" href="https://ticket.summithosting.com/hc/en-us/articles/4414878635031-Which-Version-Of-Office-Do-I-Need-" target="_blank">please see this article</a> for more information.</div>
          <Checkbox
            data-test-id="products-list.ms-own-modal.confirm-checkbox"
            absoluteStyles
            isChecked={isMsChoiceReviewed}
            label="I confirm that I own an Office 365 Apps for Enterprise, E3, E5 or Business Premium license."
            classes="m-b-16 office-modal-checkbox"
            onChange={(e) => setIsMsChoiceReviewed((e.target as HTMLInputElement).checked)}
          />
        </div>
      </Modal>
      <SignUpBackButton onClick={backHandler} />
      {currentScreen === ScreenType.List ? (
        <div className="container-xl">
          <ProgressBar currentStep={4} totalSteps={7} />

          <div className="sign-up-form-content">
            <div className="sign-up-form-step-heading m-b-8">Products List</div>
            <div className="sign-up-form-step-description m-b-40">
              Selected applications will be installed on your new Summit Server.
            </div>
            <div className="products-list">
              <div className="products-list__item">
                <div className="products-list__item__header">
                  <div className="products-list__item__line-wrapper">
                    {/* <CircleLineWrapper /> */}
                  </div>
                  <div className="products-list__item__icon">
                    <QuickbooksLogo />
                  </div>
                  <div className="products-list__item__title">
                    QuickBooks
                  </div>
                </div>
                <div className="products-list__item__content">
                  {/* <div className="products-list__item__description m-b-32 lg-m-b-24">
                    The applications will be installed on your new Summit server.
                  </div> */}
                  {quickbooksProduct ? (
                    <div className="products-list__item__product-info__container m-t--12">
                      <div className="products-list__item__product-info">
                        <CloseIcon
                          data-test-id="products-list.quickbooks-remove-icon"
                          tabIndex={0}
                          onKeyDown={onRemoveCheckedIconQuickbooksKeyDown}
                          className="close-icon"
                          onClick={removeQuickbooks}
                        />
                        <div className="flex flex-justify-between flex-align-start">
                          <div className="product-info-title">{PRODUCT_CHARGIFY_TITLES[quickbooksProduct.name] || quickbooksProduct.name}</div>
                        </div>
                        <div className="flex m-b-24 sm-m-b-16">
                          <div className="product-info-year">Year: {quickbooksProduct.year}</div>
                          <div className="product-info-users">Users: {quickbooksProduct.quantity}</div>
                        </div>
                      </div>
                      <Button
                        dataTestId="products-list.quickbooks-change-button"
                        xVersion
                        size="medium"
                        absoluteStyles
                        isDisabled={false}
                        onClick={() => changeScreen(ScreenType.Quickbooks)}
                      >
                        Change
                      </Button>
                    </div>
                  ) : (
                    <Button
                      dataTestId="products-list.quickbooks-choose-button"
                      xVersion
                      size="medium"
                      classes="sm-m-t--8"
                      absoluteStyles
                      isDisabled={false}
                      onClick={() => changeScreen(ScreenType.Quickbooks)}
                    >
                      Choose
                    </Button>
                  )}
                </div>
              </div>
              <div className="products-list__item">
                <div className="products-list__item__header">
                  <div className="products-list__item__line-wrapper">
                    {/* <CircleLineWrapper /> */}
                  </div>
                  <div className="products-list__item__icon">
                    <SageLogo />
                  </div>
                  <div className="products-list__item__title">
                    Sage
                  </div>
                </div>
                <div className="products-list__item__content">
                  {/* <div className="products-list__item__description m-b-32 lg-m-b-24">
                    The applications will be installed on your new Summit server.
                  </div> */}
                  {sageProduct ? (
                    <div className="products-list__item__product-info__container m-t--12">
                      <div className="products-list__item__product-info">
                        <CloseIcon
                          data-test-id="products-list.sage-remove-icon"
                          tabIndex={0}
                          onKeyDown={onRemoveCheckedIconSageKeyDown}
                          className="close-icon"
                          onClick={removeSage}
                        />
                        <div className="flex flex-justify-between flex-align-start">
                          <div className="product-info-title">{PRODUCT_CHARGIFY_TITLES[sageProduct.name] || sageProduct.name}</div>
                        </div>
                        <div className="flex m-b-24 sm-m-b-16">
                          <div className="product-info-year">Year: {sageProduct.year}</div>
                          <div className="product-info-users">Users: {sageProduct.quantity}</div>
                        </div>
                      </div>
                      <Button
                        dataTestId="products-list.sage-change-button"
                        xVersion
                        size="medium"
                        absoluteStyles
                        isDisabled={false}
                        onClick={() => changeScreen(ScreenType.Sage)}
                      >
                        Change
                      </Button>
                    </div>
                  ) : (
                    <Button
                      dataTestId="products-list.sage-choose-button"
                      xVersion
                      size="medium"
                      classes="sm-m-t--8"
                      absoluteStyles
                      isDisabled={false}
                      onClick={() => changeScreen(ScreenType.Sage)}
                    >
                      Choose
                    </Button>
                  )}
                </div>
              </div>
              <div className="products-list__item">
                <div className="products-list__item__header">
                  <div className="products-list__item__line-wrapper">
                    {/* <CircleLineWrapper /> */}
                  </div>
                  <div className="products-list__item__icon">
                    <MsOfficeLogo />
                  </div>
                  <div className="products-list__item__title">
                    Microsoft
                  </div>
                </div>
                <div className="products-list__item__content">
                  {/* <div className="products-list__item__description m-b-32 lg-m-b-24">
                    The applications will be installed on your new Summit server.
                  </div> */}
                  {isMsOwnConfirmed ? (
                    <div className="products-list__item__product-info__container m-t--12">
                      <div className="products-list__item__product-info">
                        <div className="flex flex-justify-between flex-align-start">
                          <div className="product-info-ms-title">Office 365 license confirmation</div>
                        </div>
                        <div className="product-info-ms-description m-b-24 sm-m-b-16">All features are available at no cost.</div>
                      </div>
                      <Button
                        dataTestId="products-list.ms-own-remove-button"
                        xVersion
                        type="default"
                        size="medium"
                        absoluteStyles
                        isDisabled={false}
                        onClick={() => setIsMsOwnConfirmed(false)}
                      >
                        Remove
                      </Button>
                    </div>
                  ) : checkedMsProductsCopy.length > 0 ? (
                    <div className="products-list__item__product-info__container m-t--12">
                      {checkedMsProductsCopy.map(({ name, quantity }) => (
                        <div className="products-list__item__product-info" key={name}>
                          <CloseIcon
                            data-test-id={`products-list.ms-product-remove-icon-${name}`}
                            tabIndex={0}
                            onKeyDown={(event) => onRemoveCheckedIconMsKeyDown(event, name)}
                            className="close-icon"
                            onClick={() => unCheckMsProduct(name)}
                          />
                          <div className="flex flex-justify-between flex-align-start">
                            <div className="product-info-title">{name}</div>
                          </div>
                          <div className="flex m-b-24 sm-m-b-16">
                            <div className="product-info-users">Users: {quantity}</div>
                          </div>
                        </div>
                      ))}
                      <Button
                        dataTestId="products-list.ms-change-button"
                        xVersion
                        size="medium"
                        absoluteStyles
                        isDisabled={false}
                        onClick={() => changeScreen(ScreenType.MsOffice)}
                      >
                        Change
                      </Button>
                    </div>
                  ) : (
                    <>
                      <Button
                        dataTestId="products-list.ms-own-button"
                        xVersion
                        size="medium"
                        absoluteStyles
                        classes="m-b-12 sm-m-t--8"
                        isDisabled={false}
                        onClick={() => setShowMsReviewModal(true)}
                      >
                        I own an Office 365 License
                      </Button>
                      <br></br>
                      <Button
                        dataTestId="products-list.ms-lease-button"
                        type="default"
                        xVersion
                        size="medium"
                        absoluteStyles
                        isDisabled={false}
                        onClick={() => changeScreen(ScreenType.MsOffice)}
                      >
                        Lease License from Summit
                      </Button>
                    </>
                  )}
                </div>
              </div>
              <div className="products-list__item">
                <div className="products-list__item__header">
                  <div className="products-list__item__line-wrapper">
                    {/* <CircleLineWrapper /> */}
                  </div>
                  <div className="products-list__item__icon icon-small">
                    <ThreeCubesIcon />
                  </div>
                  <div className="products-list__item__title">
                    Additional Applications
                  </div>
                </div>
                <div className="products-list__item__content">
                  {/* <div className="products-list__item__description m-b-32 lg-m-b-24">
                    The applications will be installed on your new Summit server.
                  </div> */}
                  {checkedOtherProductsCopy.length > 0 ? (
                    <div className="products-list__item__product-info__container m-t--12">
                      {checkedOtherProductsCopy.map(({ name, quantity, isSubscriptionProduct, id }) => (
                        <div className="products-list__item__product-info" key={name}>
                          <CloseIcon
                            data-test-id={`products-list.other-product-remove-icon-${name}`}
                            tabIndex={0}
                            onKeyDown={(event) => onRemoveCheckedIconOtherKeyDown(event, id)}
                            className="close-icon"
                            onClick={() => unCheckOtherProduct(id)}
                          />
                          <div className="flex flex-justify-between flex-align-start">
                            <div className="product-info-title">{name}</div>
                          </div>
                          <div className="flex m-b-24 sm-m-b-16">
                            <div className="product-info-users">{isSubscriptionProduct ? 'Qty' : 'Users'}: {quantity}</div>
                          </div>
                        </div>
                      ))}
                      <Button
                        dataTestId="products-list.other-change-button"
                        xVersion
                        size="medium"
                        absoluteStyles
                        isDisabled={false}
                        onClick={() => changeScreen(ScreenType.Other)}
                      >
                        Change
                      </Button>
                    </div>
                  ) : (
                    <Button
                      dataTestId="products-list.other-choose-button"
                      xVersion
                      size="medium"
                      classes="sm-m-t--8"
                      absoluteStyles
                      isDisabled={false}
                      onClick={() => changeScreen(ScreenType.Other)}
                    >
                      Choose
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-justify-center m-t-24 sm-m-t-16">
              <Button
                dataTestId="products-list.next-step-button"
                xVersion
                absoluteStyles
                isDisabled={isNextDisabled}
                isLoading={isNextLoading}
                onClick={nextHandler}
              >
                Next Step
              </Button>
            </div>
          </div>
        </div>
      ) : null}
      {currentScreen === ScreenType.Quickbooks ? <QuickbooksChoose onAdd={handleQuickbookAdd} initData={quickbooksProduct} currentStepProducts={stepProductsByCategories} /> : null}
      {currentScreen === ScreenType.Sage ? <SageChoose onAdd={handleSageAdd} initData={sageProduct} currentStepProducts={stepProductsByCategories} /> : null}
      {currentScreen === ScreenType.MsOffice ? <MsOfficeChoose onAdd={handleMsProductsAdd} initData={msProductsCopy} currentStepProducts={stepProductsByCategories} /> : null}
      {currentScreen === ScreenType.Other ? <OtherProductsChoose onAdd={handleOtherProductsAdd} initData={otherProductsCopy} currentStepProducts={stepProductsByCategories} /> : null}
      {currentScreen === ScreenType.List ? <StepPriceDetails currentStep={SignUpNavigation.ProductsList} stepProducts={STEP_PRODUCTS} /> : null}
    </>
  );
};

export default ProductsList;