import './EditApplicationModal.scss';
import { FC, useEffect, useMemo } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { PatternFormat } from 'react-number-format';

import { notify } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { editApplicationInfo } from 'store';

import {
  InputText,
  Modal,
  Select,
} from 'components';
import { ProductGroup } from 'enums';
import { LICENSE_CODE_FORMAT, LICENSE_CODE_PATTERN, MASK_SYMBOL, PRODUCT_CODE_FORMAT, PRODUCT_CODE_PATTERN } from 'consts';

const initialProductForm: any = {
  name: '',
  year: '',
  productCode: '',
  serialNumber: '',
  licenseCode: '',
};

interface IProductData {
  id: string;
  title: string;
  name: string;
  productVersion: string;
  licenceCode: string;
  licenseCode: string;
  years: string[];
  productCode: string;
  serialNumber: string;
  price: string;
  productId: string;
  year: string;
  groupName: string;
}

interface Props {
  visible: boolean;
  productData: IProductData;
  subscriptionId?: string;
  isAdminPortal?: boolean;
  onCancel: () => void;
  onSuccessEdit: () => void;
}

const schema = yup.object({
});

const EditApplicationModal: FC<Props> = ({
  visible,
  productData,
  subscriptionId,
  isAdminPortal,
  onCancel,
  onSuccessEdit,
}) => {
  const {
    getValues,
    register,
    resetField,
    control,
    handleSubmit,
    formState,
    watch,
  } = useForm<any>({ mode: 'all', defaultValues: initialProductForm });
  const { errors, isValid } = formState;

  const isFormNotChanged = useMemo(() => {
    const formValues = getValues();
    const {
      name = '',
      year = '',
      productCode = '',
      serialNumber = '',
      licenseCode = '',
    } = productData || {} as any;

    return (
      (formValues.name ?? '') === (name ?? '') &&
      (formValues.year ?? '') === ((year && year.toString()) ?? '') &&
      (formValues.productCode ?? '') === (productCode ?? '') &&
      (formValues.serialNumber ?? '') === (serialNumber ?? '') &&
      (formValues.licenseCode ?? '') === (licenseCode ?? '')
    );
  }, [watch()]);

  const resetModal = (productData?: any | null) => {
    const {
      name = '',
      year = '',
      productCode = '',
      serialNumber = '',
      licenseCode = '',
    } = productData || {} as any;

    resetField('name', { defaultValue: name });
    resetField('year', { defaultValue: year && year.toString() });
    resetField('productCode', { defaultValue: productCode });
    resetField('serialNumber', { defaultValue: serialNumber });
    resetField('licenseCode', { defaultValue: licenseCode });
  };

  useEffect(() => {
    if (visible) {
      resetModal(productData);
    }
  }, [visible]);

  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector(state => state.ussp.editApplicationInfoRequest);
  const onNextHandler: SubmitHandler<any> = async data => {
    if (typeof subscriptionId === 'string' && typeof productData?.id === 'string') {
      await dispatch(editApplicationInfo({
        isAdminPortal: true,
        subscriptionId,
        id: productData.id,
        name: data.name,
        year: data.year && Number(data.year) || null,
        productCode: data.productCode || null,
        serialNumber: data.serialNumber || null,
        licenceCode: data.licenseCode || null,
      })).unwrap();

      notify.success(
        'Success',
        'Application was successfully edited',
      );
      onSuccessEdit();
      onCancel();
    }
  };

  const isOkDisabled = useMemo(() => {
    return !isValid || isFormNotChanged;
  }, [isValid, isFormNotChanged]);

  return (
    <Modal
      classes="dashboard-accounts-edit-user"
      title="Edit Application"
      visible={visible}
      width={535}
      okText="Save"
      isOkDisabled={isOkDisabled}
      isOkLoading={isLoading}
      onOk={handleSubmit(onNextHandler)}
      onCancel={onCancel}
    >
      <form>
        <InputText
          classes="rel-m-b-20"
          label="Name of application"
          placeholder="Enter the application name"
          name="name"
          register={register}
          shouldUnregister={false}
          isDisabled
          isInvalid={!!errors.name}
        />
        {
          productData.groupName !== ProductGroup.Other
            ? productData?.years?.length
              ? (
                <Controller
                  name="year"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      classes="rel-m-b-16"
                      value={value}
                      onClose={onBlur}
                      onChange={onChange}
                      label="Choose year"
                      placeholder="Please choose year"
                      options={productData.years.map(String)}
                    />
                  )}
                />
              )
              : (
                <InputText
                  classes="rel-m-b-20"
                  label="Enter the year"
                  placeholder="Enter the year"
                  name="year"
                  register={register}
                  shouldUnregister={false}
                  isInvalid={!!errors.year}
                />
              )
            : null
        }
        {
          productData.groupName === ProductGroup.Other && (
            <InputText
              classes="rel-m-b-20"
              label="Enter the product code"
              placeholder="Enter product code"
              name="productCode"
              register={register}
              shouldUnregister={false}
              isInvalid={!!errors.productCode}
            />
          )
        }
        {
          productData.groupName === ProductGroup.Quickbooks && (
            <Controller
              name="productCode"
              control={control}
              defaultValue=""
              rules={{ required: false, pattern: PRODUCT_CODE_PATTERN }}
              shouldUnregister
              render={({
                field: { onChange, onBlur, value },
                fieldState: { invalid, isTouched },
              }) => (
                <PatternFormat
                  format={PRODUCT_CODE_FORMAT}
                  mask={MASK_SYMBOL}
                  customInput={InputText}
                  classes="rel-m-b-32"
                  label="Product code"
                  placeholder="000-000"
                  value={value}
                  isInvalid={isTouched && invalid}
                  isReturnEvent
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          )}
        {
          productData.groupName !== ProductGroup.Quickbooks && (
            <InputText
              classes="rel-m-b-20"
              label="Enter the serial number"
              placeholder="Enter serial number"
              name="serialNumber"
              register={register}
              shouldUnregister={false}
              isInvalid={!!errors.serialNumber}
            />
          )
        }
        {
          productData.groupName === ProductGroup.Other && (
            <InputText
              classes="rel-m-b-20"
              label="Enter the license code"
              placeholder="Enter license code"
              name="licenseCode"
              register={register}
              shouldUnregister={false}
              isInvalid={!!errors.licenseCode}
            />
          )
        }
        {
          productData.groupName === ProductGroup.Quickbooks && (
            <Controller
              name="licenseCode"
              control={control}
              defaultValue=""
              rules={{ required: false, pattern: LICENSE_CODE_PATTERN }}
              shouldUnregister
              render={({
                field: { onChange, onBlur, value },
                fieldState: { invalid, isTouched },
              }) => (
                <PatternFormat
                  format={LICENSE_CODE_FORMAT}
                  mask={MASK_SYMBOL}
                  customInput={InputText}
                  classes="rel-m-b-24"
                  label="License code"
                  placeholder="0000-0000-0000-000"
                  value={value}
                  isInvalid={isTouched && invalid}
                  isReturnEvent
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          )}

      </form>
    </Modal>
  );
};

export default EditApplicationModal;