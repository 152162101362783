export * from './http';

import { AllCountriesIcon, CanadaSmallIcon, OtherCountryIcon, UsaSmallIcon } from 'assets/svg';
import {
  InvoiceStatus,
  BillingStatus,
  CanadaProvince,
  CountryCode,
  HostProduct,
  ProductChargify,
  OrderNavigation,
  QuickbookOption,
  USAStates,
} from 'enums';

/**
 * Regex for validate domain name
 */
export const DOMAIN_NAME_PATTERN = /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;

/**
 * Regex for validate email
 */
export const EMAIL_PATTERN = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

/**
 * Regex for validate number
 */
export const NUMBER_PATTERN = /^\d+$/;

/**
 * Regex for validate phone number
 */
export const PHONE_PATTERN = /^[+](\d{1,3})[\s]\(\d{3}\)[\s]\d{3}[-]\d{4}$/;
export const PHONE_PATTERN_SIMPLE = /^(?!\s)(?!.*\s$)(\+?)[0-9\s()-]+$/;
export const PHONE_FORMAT = '+1 (###) ###-####';

/**
 * Regex for validate product code
 */
export const PRODUCT_CODE_PATTERN = /^\d{3}[-]\d{3}$/;
export const PRODUCT_CODE_FORMAT = '###-###';

export const MASK_SYMBOL = '_';

/**
 * Regex for validate licence code
 */
export const LICENSE_CODE_PATTERN = /^\d{4}[-]\d{4}[-]\d{4}[-]\d{3}$/;
export const LICENSE_CODE_FORMAT = '####-####-####-###';

export const SAGE_PRODUCTS_LICENSE_FORMAT: { [key: string]: string } = {
  [ProductChargify.Sage50Premium]: '####-####-####-####',
  [ProductChargify.Sage50Quantum]: '####-####-####-####',
  [ProductChargify.Sage50Intelligence]: '####-####-####-####-####',
  [ProductChargify.SageFixedAssets]: '###-#####-#####',
  [ProductChargify.SageTimeslips]: '#####-#####-#####',
  [ProductChargify.SageBusinessWorks]: '###-#####-#####',
};
export const SAGE_PRODUCTS_LICENSE_PATTERN: { [key: string]: RegExp } = {
  [ProductChargify.Sage50Premium]: /^\d{4}[-]\d{4}[-]\d{4}[-]\d{4}$/,
  [ProductChargify.Sage50Quantum]: /^\d{4}[-]\d{4}[-]\d{4}[-]\d{4}$/,
  [ProductChargify.Sage50Intelligence]: /^\d{4}[-]\d{4}[-]\d{4}[-]\d{4}[-]\d{4}$/,
  [ProductChargify.SageFixedAssets]: /^\d{3}[-]\d{5}[-]\d{5}$/,
  [ProductChargify.SageTimeslips]: /^\d{5}[-]\d{5}[-]\d{5}$/,
  [ProductChargify.SageBusinessWorks]: /^\d{3}[-]\d{5}[-]\d{5}$/,
};
/**
 * Regex for postal code
 */
export const POSTAL_CODE_PATTERN = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

/**
 * Regex for zip code
 */
export const ZIP_CODE_PATTERN = /^[0-9]+$/;

export const MS_OFFICE_PRODUCTS = [
  ProductChargify.OfficeStandardLease,
  ProductChargify.OfficeProfessionalLease,
  ProductChargify.WordLease,
  ProductChargify.ExcelLease,
  ProductChargify.PowerpointLease,
  ProductChargify.OutlookLease,
  ProductChargify.AccessLease,
];

export const ADDITIONAL_SERVICES_PRODUCTS = [
  ProductChargify.BackupRetentionIncrease1Year,
  ProductChargify.BackupRetentionIncrease2Year,
  ProductChargify.BackupRetentionIncrease3Year,
  ProductChargify.ExternalStaticIPAddress,
  ProductChargify.VPN,
];

export const SAGE_PRODUCTS = [ProductChargify.Sage50Premium, ProductChargify.Sage50Quantum];

export const SAGE_OTHER_PRODUCTS: ProductChargify[] = [
  ProductChargify.Sage50Intelligence,
  ProductChargify.SageTimeslips,
];

export const ALL_SAGE_PRODUCTS = [
  ...SAGE_PRODUCTS,
  ...SAGE_OTHER_PRODUCTS,
];

export const PRODUCT_CHARGIFY_TITLES: { [key: string]: string } = {
  [QuickbookOption.Own]: 'Your QuickBooks license details',
  [QuickbookOption.Buy]: 'I want to buy a QuickBooks license from Summit',

  [ProductChargify.QuickBooksEnterpriseDiamond]: 'Enterprise Diamond',
  [ProductChargify.QuickBooksEnterprisePlatinum]: 'Enterprise Platinum',
  [ProductChargify.QuickBooksEnterpriseGold]: 'Enterprise Gold',
  [ProductChargify.QuickBooksEnterpriseSilver]: 'Enterprise Silver',
  [ProductChargify.QuickBooksPremierAccountant]: 'Premier Accountant',
  [ProductChargify.QuickBooksPremierPremierPlus]: 'Premier/Premier Plus',
  [ProductChargify.QuickBooksProProPlus]: 'Pro/Pro Plus',

  [ProductChargify.QuickBooksEnterpriseDiamondPurchase]: 'Enterprise Diamond',
  [ProductChargify.QuickBooksEnterprisePlatinumWithAdvancedInventoryPurchase]: 'Enterprise Platinum with Advanced Inventory',
  [ProductChargify.QuickBooksEnterpriseGoldPurchase]: 'Enterprise Gold',
  [ProductChargify.QuickBooksEnterpriseSilverPurchase]: 'Enterprise Silver',
  [ProductChargify.QuickBooksPremierAccountantPurchase]: 'Premier Accountant',
  [ProductChargify.QuickBooksPremierPlusPurchase]: 'Premier Plus',
  [ProductChargify.QuickBooksProPlusPurchase]: 'Pro Plus',

  [ProductChargify.QuickBooksPro]: 'Pro',
  [ProductChargify.QuickBooksPremier]: 'Premier',
  [ProductChargify.QuickBooksEnterprise]: 'Enterprise',
  [ProductChargify.QuickBooksEnterpriseWithAdvancedInventory]: 'Enterprise with Advanced Inventory',

  [ProductChargify.QuickBooksProPurchase]: 'Pro',
  [ProductChargify.QuickBooksPremierPurchase]: 'Premier',
  [ProductChargify.QuickBooksEnterprisePurchase]: 'Enterprise',
  [ProductChargify.QuickBooksEnterpriseWithAdvancedInventoryPurchase]: 'Enterprise with Advanced Inventory',

  [ProductChargify.Sage50Premium]: 'Sage 50 Premium',
  [ProductChargify.Sage50Quantum]: 'Sage 50 Quantum',
  [ProductChargify.SageFixedAssets]: 'Sage Fixed Assets',
  [ProductChargify.SageTimeslips]: 'Sage Timeslips',
  [ProductChargify.SageBusinessWorks]: 'Sage Business Works',
  [ProductChargify.Sage50Intelligence]: 'Sage 50 Intelligence Reporting',
  [ProductChargify.MSOfficeOwn]: 'I own an Office 365 Apps for Enterprise (Formerly ProPlus), E3, E5 or Business Premium license to use ',
  [ProductChargify.OfficeStandardLease]: 'Office 2016 Standard Lease (Word, Excel, Powerpoint, and Outlook)',
  [ProductChargify.OfficeProfessionalLease]: 'Office 2016 Professional Lease (Office Standard + One Note, Publisher, and Access)',
  [ProductChargify.WordLease]: 'Word 2016 Lease',
  [ProductChargify.ExcelLease]: 'Excel 2016 Lease',
  [ProductChargify.PowerpointLease]: 'Powerpoint 2016 Lease',
  [ProductChargify.OutlookLease]: 'Outlook 2016 Lease',
  [ProductChargify.AccessLease]: 'Access 2016 Lease',
  [ProductChargify.SSW]: 'Summit Secure Workspace (SSW)',
  [ProductChargify.Deposit]: 'Deposit',

  [ProductChargify.BackupRetentionIncrease1Year]: '12 Months Extended Retention',
  [ProductChargify.BackupRetentionIncrease2Year]: '24 Months Extended Retention',
  [ProductChargify.BackupRetentionIncrease3Year]: '36 Months Extended Retention',
  [ProductChargify.VPN]: 'VPN Server',
  [ProductChargify.ExternalStaticIPAddress]: 'External Static IP Address',
};

export const PRODUCTS_PAGES_NAV: { [key: string]: OrderNavigation} = {
  [HostProduct.Quickbooks]: OrderNavigation.Quickbooks,
  [HostProduct.Sage]: OrderNavigation.Sage,
};

export const QUICKBOOK_OWN_MAP_BY_COUNTRY: { [key: string]: { name: ProductChargify }[] } = {
  [CountryCode.USA]: [
    { name: ProductChargify.QuickBooksEnterpriseDiamond },
    { name: ProductChargify.QuickBooksEnterprisePlatinum },
    { name: ProductChargify.QuickBooksEnterpriseGold },
    { name: ProductChargify.QuickBooksEnterpriseSilver },
    { name: ProductChargify.QuickBooksPremierAccountant },
    { name: ProductChargify.QuickBooksPremierPremierPlus },
    { name: ProductChargify.QuickBooksProProPlus },
  ],
  [CountryCode.Canada]: [
    { name: ProductChargify.QuickBooksPro },
    { name: ProductChargify.QuickBooksPremier },
    { name: ProductChargify.QuickBooksEnterprise },
    { name: ProductChargify.QuickBooksEnterpriseWithAdvancedInventory },
  ],
};

export const QUICKBOOK_BUY_MAP_BY_COUNTRY: { [key: string]: { name: ProductChargify }[] } = {
  [CountryCode.USA]: [
    { name: ProductChargify.QuickBooksEnterpriseDiamondPurchase },
    { name: ProductChargify.QuickBooksEnterprisePlatinumWithAdvancedInventoryPurchase },
    { name: ProductChargify.QuickBooksEnterpriseGoldPurchase },
    { name: ProductChargify.QuickBooksEnterpriseSilverPurchase },
    { name: ProductChargify.QuickBooksPremierAccountantPurchase },
    { name: ProductChargify.QuickBooksPremierPlusPurchase },
    { name: ProductChargify.QuickBooksProPlusPurchase },
  ],
  [CountryCode.Canada]: [
    { name: ProductChargify.QuickBooksProPurchase },
    { name: ProductChargify.QuickBooksPremierPurchase },
    { name: ProductChargify.QuickBooksEnterprisePurchase },
    { name: ProductChargify.QuickBooksEnterpriseWithAdvancedInventoryPurchase },
  ],
};

export const USA_STATES_LABELS = {
  [USAStates.Alabama]: 'Alabama',
  [USAStates.Alaska]: 'Alaska',
  [USAStates.Arizona]: 'Arizona',
  [USAStates.Arkansas]: 'Arkansas',
  [USAStates.California]: 'California',
  [USAStates.Colorado]: 'Colorado',
  [USAStates.Connecticut]: 'Connecticut',
  [USAStates.Delaware]: 'Delaware',
  [USAStates.Florida]: 'Florida',
  [USAStates.Georgia]: 'Georgia',
  [USAStates.Hawaii]: 'Hawaii',
  [USAStates.Idaho]: 'Idaho',
  [USAStates.Illinois]: 'Illinois',
  [USAStates.Indiana]: 'Indiana',
  [USAStates.Iowa]: 'Iowa',
  [USAStates.Kansas]: 'Kansas',
  [USAStates.Kentucky]: 'Kentucky',
  [USAStates.Louisiana]: 'Louisiana',
  [USAStates.Maine]: 'Maine',
  [USAStates.Maryland]: 'Maryland',
  [USAStates.Massachusetts]: 'Massachusetts',
  [USAStates.Michigan]: 'Michigan',
  [USAStates.Minnesota]: 'Minnesota',
  [USAStates.Mississippi]: 'Mississippi',
  [USAStates.Missouri]: 'Missouri',
  [USAStates.Montana]: 'Montana',
  [USAStates.Nebraska]: 'Nebraska',
  [USAStates.Nevada]: 'Nevada',
  [USAStates.NewHampshire]: 'New Hampshire',
  [USAStates.NewJersey]: 'New Jersey',
  [USAStates.NewMexico]: 'New Mexico',
  [USAStates.NewYork]: 'New York',
  [USAStates.NorthCarolina]: 'North Carolina',
  [USAStates.NorthDakota]: 'North Dakota',
  [USAStates.Ohio]: 'Ohio',
  [USAStates.Oklahoma]: 'Oklahoma',
  [USAStates.Oregon]: 'Oregon',
  [USAStates.Pennsylvania]: 'Pennsylvania',
  [USAStates.RhodeIsland]: 'Rhode Island',
  [USAStates.SouthCarolina]: 'South Carolina',
  [USAStates.SouthDakota]: 'South Dakota',
  [USAStates.Tennessee]: 'Tennessee',
  [USAStates.Texas]: 'Texas',
  [USAStates.Utah]: 'Utah',
  [USAStates.Vermont]: 'Vermont',
  [USAStates.Virginia]: 'Virginia',
  [USAStates.Washington]: 'Washington',
  [USAStates.WestVirginia]: 'West Virginia',
  [USAStates.Wisconsin]: 'Wisconsin',
  [USAStates.Wyoming]: 'Wyoming',
};

export const CANADA_PROVINCE_LABELS = {
  [CanadaProvince.Alberta]: 'Alberta',
  [CanadaProvince.BritishColumbia]: 'British Columbia',
  [CanadaProvince.Manitoba]: 'Manitoba',
  [CanadaProvince.NewBrunswick]: 'New Brunswick',
  [CanadaProvince.NewfoundlandAndLabrador]: 'Newfoundland and Labrador',
  [CanadaProvince.NorthwestTerritories]: 'Northwest Territories',
  [CanadaProvince.NovaScotia]: 'Nova Scotia',
  [CanadaProvince.Nunavut]: 'Nunavut',
  [CanadaProvince.Ontario]: 'Ontario',
  [CanadaProvince.PrinceEdwardIsland]: 'Prince Edward Island',
  [CanadaProvince.Quebec]: 'Quebec',
  [CanadaProvince.Saskatchewan]: 'Saskatchewan',
  [CanadaProvince.YukonTerritory]: 'Yukon Territory',
};

export const BILLING_STATUS_CLASSES = {
  [BillingStatus.Active]: 'green-3',
  [BillingStatus.Canceled]: 'red-1',
  [BillingStatus.Expired]: 'red-1',
  [BillingStatus.OnHold]: 'yellow-2',
  [BillingStatus.PastDue]: 'red-1',
  [BillingStatus.SoftFailure]: 'red-1',
  [BillingStatus.Trialing]: 'yellow-1',
  [BillingStatus.TrialEnded]: 'red-1',
  [BillingStatus.Unpaid]: 'red-1',
  [BillingStatus.Suspended]: 'grey-5',
  [BillingStatus.AwaitingSignup]: 'yellow-1',
};

export const INVOICE_STATUS_CLASSES = { // @todo discuss about colors
  [InvoiceStatus.Draft]: 'grey-5',
  [InvoiceStatus.Open]: 'yellow-1',
  [InvoiceStatus.Paid]: 'green-3',
  [InvoiceStatus.Pending]: 'yellow-1',
  [InvoiceStatus.Voided]: 'red-1',
  [InvoiceStatus.Canceled]: 'red-1',
};

export const MANAGABLE_TYPES = {
  UPDATED: 'Updated',
  NON_MANAGEABLE: 'Non manageable',
};

export const MANAGABLE_TYPES_LIST = [
  {
    label: MANAGABLE_TYPES.UPDATED,
    value: 'true',
  },
  {
    label: MANAGABLE_TYPES.NON_MANAGEABLE,
    value: 'false',
  },
];

export const SUPPORT_LINK = 'https://ticket.summithosting.com/hc/en-us';

export const INACTIVE_IDLE = +(process.env.REACT_APP_INACTIVE_IDLE || 900); // 15 min

export const EXPIRED_TIME = 'expiredTime';

export const INTENDED_URL = 'intendedUrl';
export const SESSION_EXPIRED = 'sessionExpired';
export const LOGS = 'logs';

export const COUNTRY_NAMES_BY_CODE = {
  [CountryCode.USA]: 'USA',
  [CountryCode.Canada]: 'Canada',
  [CountryCode.Other]: 'Other',
};
export const COUNTRY_NUMBER_BY_CODE = {
  [CountryCode.USA]: 0,
  [CountryCode.Canada]: 1,
  [CountryCode.Other]: 2,
};

export const COUNTRY_FLAG_BY_COUNTRY_CODE = {
  [CountryCode.USA]: UsaSmallIcon,
  [CountryCode.Canada]: CanadaSmallIcon,
  [CountryCode.Other]: OtherCountryIcon,
};

export const COUNTRY_FILTER_OPTIONS = [
  {
    value: 'All',
    label: 'ALL',
    icon: AllCountriesIcon,
  },
  {
    value: CountryCode.USA,
    label: COUNTRY_NAMES_BY_CODE[CountryCode.USA].toUpperCase(),
    icon: COUNTRY_FLAG_BY_COUNTRY_CODE[CountryCode.USA],
  },
  {
    value: CountryCode.Canada,
    label: COUNTRY_NAMES_BY_CODE[CountryCode.Canada].toUpperCase(),
    icon: COUNTRY_FLAG_BY_COUNTRY_CODE[CountryCode.Canada],
  },
  {
    value: CountryCode.Other,
    label: COUNTRY_NAMES_BY_CODE[CountryCode.Other].toUpperCase(),
    icon: COUNTRY_FLAG_BY_COUNTRY_CODE[CountryCode.Other],
  },
];
