import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from 'assets/svg';
import { toast } from 'react-toastify';

const notify = {
  warning: (title: string, message?: string, autoClose?: number, toastId?: string) => toast.warning(
    <>
      <div className="Toastify__toast-title">{title}</div>
      {message &&
        <div className="Toastify__toast-message">{message}</div>
      }
    </>, {
      autoClose,
      toastId,
      icon: () => <WarningIcon/>,
    }),
  error: (title: string, message?: string, autoClose?: number, toastId?: string) => toast.error(
    <>
      <div className="Toastify__toast-title">{title}</div>
      {message &&
        <div className="Toastify__toast-message">{message}</div>
      }
    </>, {
      autoClose,
      toastId,
      icon: () => <ErrorIcon/>,
    }),
  success: (title: string, message?: string, autoClose?: number, toastId?: string) => toast.success(
    <>
      <div className="Toastify__toast-title">{title}</div>
      {message &&
        <div className="Toastify__toast-message">{message}</div>
      }
    </>, {
      autoClose,
      toastId,
      icon: () => <SuccessIcon/>,
    }),
  info: (title: string, message?: string, autoClose?: number, toastId?: string) => toast.info(
    <>
      <div className="Toastify__toast-title">{title}</div>
      {message &&
        <div className="Toastify__toast-message">{message}</div>
      }
    </>, {
      autoClose,
      toastId,
      icon: () => <InfoIcon/>,
    }),
};

export {
  notify,
};