import { ProductChargify, CountryCode } from 'enums';
import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { getEnvCountryCode } from './utils';

export const usePriceHandler = () => {
  const currentCountryCode = useAppSelector(state => state.order.country?.code || CountryCode.USA);
  const envCountryCode = useMemo(() => getEnvCountryCode(currentCountryCode), [currentCountryCode]);
  const products = useAppSelector(state => state.products.products);

  const formatPrice = (price: number | null, countryCode?: string | CountryCode, isCents?: boolean, withFractionDigits?: boolean) => {
    const cadPrefics = countryCode === CountryCode.Canada ? ' CAD' : '';
    if (!!price || price === 0) {
      const normalizedPrice = price / (isCents ? 100 : 1);
      const formattedPrice = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: withFractionDigits ? 2 : normalizedPrice % 1 === 0 ? 0 : 2,
        maximumFractionDigits: 2,
      }).format(normalizedPrice);
  
      return `$${formattedPrice}${cadPrefics}`;
    }
    return `N/A${cadPrefics}`;
  };

  const calculateProductPrice = (name: ProductChargify, quantity: string = '1') => {
    let result = 0;
    const product = products[name];
    if (product && Object.prototype.hasOwnProperty.call(product, 'price')) {
      result = product.price * (+quantity || 0);
    }
    return result;
  };

  const getProductPrice = (name: ProductChargify, quantity: string = '1') => {
    const calculatedPrice = calculateProductPrice(name, quantity);
    return formatPrice(calculatedPrice, envCountryCode);
  };

  const getSinglePriceAmountInDollars = (name: ProductChargify) => {
    const calculatedPrice = calculateProductPrice(name);
    return calculatedPrice;
  };

  return { calculateProductPrice, formatPrice, getProductPrice, getSinglePriceAmountInDollars };
};