import './CompanyDetails.scss';
import { FC, useRef, useState, useMemo, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useFormFieldErrorNotification, useSignUpFormNavigate, useSimpleFormNavigate } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  createUser,
  createOrder,
  getProductsInfo,
  getOtherProductsInfo,
  resetOrder,
  setCompanyName,
  setOrderId,
  setCompanyInfoId,
  setCountry,
  setUserDetails,
  getAccessToken,
  getCountries,
  getCountryStates,
  setOtherProducts,
  getSubscriptionTerms,
} from 'store';

import { UsaNewIcon, CanadaNewIcon, CountryNoFlagIcon } from 'assets/svg';

import {
  CANADA_PROVINCE_LABELS,
  EMAIL_PATTERN,
  POSTAL_CODE_PATTERN,
  PHONE_PATTERN_SIMPLE,
  USA_STATES_LABELS,
  ZIP_CODE_PATTERN,
  ERROR_MESSAGES_BY_SPECIFIC_CODE,
  COUNTRY_NAMES_BY_CODE,
  PHONE_FORMAT,
  MASK_SYMBOL,
} from 'consts';
import {
  CanadaProvince,
  CountryCode,
  USAStates,
  AccessTokenType,
  RefreshTokenType,
  SpecificCode,
  SignUpNavigation,
} from 'enums';
import { ICompanyInfo, ICountry, ICountryState, IOtherProduct } from 'interfaces';

import {
  InputText,
  Select,
  ProgressBar,
  Button,
  SignUpBackButton,
} from 'components';
import { PatternFormat } from 'react-number-format';

interface IUserDetailsFields {
  country: CountryCode;
  firstName: string;
  lastName: string;
  email: string;
}
interface ICreateUserDetailsRes {
  id: string;
  companyInfoId: string;
}

type CountryForm = {
  companyName: string;
  companyDomainName: string;
  companyInfo: ICompanyInfo;
};

const PROVINCE_FIELD_DATA = {
  [CountryCode.USA]: {
    label: 'State',
    placeholder: 'Select state',
    options: Object.values(USAStates).map(value => ({
      label: USA_STATES_LABELS[value],
      value,
    })),
  },
  [CountryCode.Canada]: {
    label: 'Province',
    placeholder: 'Select province',
    options: Object.values(CanadaProvince).map(value => ({
      label: CANADA_PROVINCE_LABELS[value],
      value,
    })),
  },
  [CountryCode.Other]: {
    label: 'State',
    placeholder: 'Select state',
    options: Object.values(USAStates).map(value => ({
      label: USA_STATES_LABELS[value],
      value,
    })),
  },
};

const POSTAL_CODE_FIELD_DATA = {
  [CountryCode.USA]: {
    label: 'Zip Code',
    placeholder: 'Enter zip code',
  },
  [CountryCode.Canada]: {
    label: 'Postal Code',
    placeholder: 'Enter postal code',
  },
  [CountryCode.Other]: {
    label: 'Zip Code',
    placeholder: 'Enter zip code',
  },
};

const CompanyDetails: FC = () => {
  const { country: prevStepCountry, firstName, lastName, email } = useAppSelector(
    state => state.order.userDetailsFields || { country: CountryCode.USA, firstName: '', lastName: '', email: '' } as IUserDetailsFields
  );
  const [initCountry] = useState(prevStepCountry);
  const { data: otherCountries = []}: { data?: ICountry[] } = useAppSelector(
    state => state.country.getCountriesRequest || {},
  );
  const otherCountriesSorted = useMemo(() => otherCountries.slice().sort((a, b) => a.name > b.name ? 1 : -1), [otherCountries]);
  const {
    data: otherCountryStates = [],
    isLoading: isLoadingOtherCountryStates = false,
  }: { data?: ICountryState[]; isLoading: boolean } = useAppSelector(
    state => state.country.getCountryStatesRequest || {},
  );
  const schema = useMemo(() => yup.object({
    companyName: yup.string().required('Business Name is required'),
    companyDomainName: yup.string().required('Business Email is required')
      .matches(EMAIL_PATTERN, 'Please enter a valid Business Email'),
    companyInfo: yup.object({
      phoneNumber: yup.string().required('Business Phone is required')
        .matches(PHONE_PATTERN_SIMPLE, 'Please enter a valid Business Phone')
        .max(20, 'Phone number can\'t be longer than 20 digits'),
      address1: yup.string().required('Business Address is required'),
      address2: yup.string(),
      city: yup.string().required('City is required'),
      postalCode: yup.lazy(() => {
        if (initCountry === CountryCode.Canada) {
          return yup.string().required('Postal Code is required')
            .matches(POSTAL_CODE_PATTERN, 'Please enter a valid Postal code');
        }
        if (initCountry === CountryCode.USA) {
          return yup.string().required('Zip Code is required')
            .matches(ZIP_CODE_PATTERN, 'Please enter a valid Zip Code')
            .min(5, 'Must be exactly 5 digits')
            .max(5, 'Must be exactly 5 digits');
        }
        return yup.string().required('Zip Code is required');
      }),
      ...(initCountry === CountryCode.Other ? {
        otherCountryCode: yup.string().required('Country is required'),
      } : {}),
      province: yup.lazy(() => {
        if (initCountry !== CountryCode.Other || isLoadingOtherCountryStates || otherCountryStates.length > 0) {
          return yup.string().required(
            `${PROVINCE_FIELD_DATA[initCountry].label} is required`,
          );
        }

        return yup.string();
      }),
    }),
  }), [initCountry, otherCountryStates, isLoadingOtherCountryStates]);

  const {
    control,
    getValues,
    formState,
    handleSubmit,
    register,
    resetField,
    setError,
    watch,
  } = useForm<CountryForm>({
    mode: 'all', resolver: yupResolver(schema),
  });
  const { errors, isValid, touchedFields } = formState;

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isNextLoading, setIsNextLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCountries()).unwrap();
  }, []);
  const fetchCountryStates = async (countryCode: string): Promise<void> => {
    await dispatch(getCountryStates({ countryCode })).unwrap();
    resetField('companyInfo.province', { keepDirty: false, keepTouched: false, keepError: false });
  };

  const isDisablePovinceField = useMemo(
    () => initCountry === CountryCode.Other
      && (!getValues('companyInfo.otherCountryCode') || isLoadingOtherCountryStates || otherCountryStates.length <= 0),
    [initCountry, watch('companyInfo.otherCountryCode'), isLoadingOtherCountryStates, otherCountryStates],
  );

  const simpleFormNavigate = useSignUpFormNavigate();
  const onNextHandler: SubmitHandler<CountryForm> = async (data) => {
    setIsNextLoading(true);
    try {
      const token = !!executeRecaptcha && await executeRecaptcha('companyDetailsPage');
      const { companyName, companyDomainName, companyInfo } = data;
      const country: CountryCode = initCountry === CountryCode.Other
        ? (companyInfo.otherCountryCode as CountryCode) || CountryCode.USA
        : initCountry;
      const userData = {
        email: email.trim(),
        captcha_response: token,
        country,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        company_name: companyName.trim(),
        company_domain_name: companyDomainName.trim(),
        company_info: {
          phone_number: companyInfo.phoneNumber.trim(),
          address_1: companyInfo.address1.trim(),
          address_2: companyInfo.address2.trim(),
          city: companyInfo.city.trim(),
          postal_code: companyInfo.postalCode,
          province: companyInfo.province,
        },
      };

      dispatch(setCountry({
        code: country,
        name: initCountry === CountryCode.Other
          ? (otherCountries.find(({ code }) => code === country) ?? {}).name || ''
          : COUNTRY_NAMES_BY_CODE[initCountry],
      }));
      const {
        id: userId,
        companyInfoId,
      }: ICreateUserDetailsRes = await dispatch(createUser(userData)).unwrap();

      // get access token and save to session storage
      const tokenResponse: {
        access_token: string;
        refresh_token: string;
      } = await dispatch(getAccessToken(email)).unwrap();
      if (tokenResponse && tokenResponse.access_token) {
        sessionStorage.setItem(AccessTokenType.AccessTokenSf, tokenResponse.access_token);
      }
      if (tokenResponse && tokenResponse.refresh_token) {
        sessionStorage.setItem(RefreshTokenType.RefreshTokenSf, tokenResponse.refresh_token);
      }

      dispatch(setCompanyInfoId(companyInfoId));
      const countryProducts = await dispatch(getProductsInfo(country)).unwrap();
      const countryProductsNames = (countryProducts?.products || []).map(({ name }: { name: string }) => name);
      const otherProducts = await dispatch(getOtherProductsInfo()).unwrap();
      dispatch(setOtherProducts((otherProducts as { id: string; version: string; price: number, isSubscriptionProduct: boolean }[]).reduce(
        (productsList, { id, version: name, price, isSubscriptionProduct }) => {
          if (!countryProductsNames.includes(name)) {
            productsList.push({ id, name, price, isChecked: false, quantity: '', isSubscriptionProduct });
          }

          return productsList;
        },
        [] as IOtherProduct[],
      )));
      await dispatch(getSubscriptionTerms()).unwrap();
      const orderId: string = await dispatch(createOrder({
        country,
        userId,
      })).unwrap();
      dispatch(setCompanyName(companyName));
      dispatch(setOrderId(orderId));
      dispatch(setUserDetails({
        firstName,
        lastName,
        email,
        id: userId,
        ...data,
      }));

      simpleFormNavigate(SignUpNavigation.ServerUserHosting);
    } catch (e: any) {
      //error
    } finally {
      setIsNextLoading(false);
    }
  };
  const backHandler = () => {
    simpleFormNavigate(SignUpNavigation.UserDetails, { country: initCountry, firstName, lastName, email });
  };

  return (
    <>
      <SignUpBackButton onClick={backHandler} />
      <div className="container-sm">
        <ProgressBar currentStep={2} totalSteps={7} />
        <div className="sign-up-form-content">
          <form>
            <div className="sign-up-form-step-section-heading m-b-20 sm-m-b-12">Business Details</div>
            <InputText
              dataTestId="company-details.company-name-input"
              xVersion
              absoluteStyles
              isRequired
              label="Business Name"
              name="companyName"
              classes="m-b-20 sm-m-b-12"
              register={register}
              isInvalid={!!(errors.companyName && touchedFields.companyName)}
              errorText={errors.companyName?.message}
            />
            <InputText
              dataTestId="company-details.company-domain-input"
              xVersion
              absoluteStyles
              isRequired
              label="Business Email"
              name="companyDomainName"
              register={register}
              classes="m-b-20 sm-m-b-12"
              // infoContent="Please provide email address you are using for work within your organisation"
              isInvalid={!!(errors.companyDomainName && touchedFields.companyDomainName)}
              errorText={errors.companyDomainName?.message}
            />
            <Controller
              name="companyInfo.phoneNumber"
              control={control}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { isTouched, invalid, error },
              }) => initCountry === CountryCode.Other
                ? (
                  <InputText
                    dataTestId="company-details.company-phone-input"
                    absoluteStyles
                    isRequired
                    xVersion
                    phoneFormating
                    label="Business Phone"
                    maxLength={20}
                    value={value}
                    classes="m-b-20 sm-m-b-12"
                    isInvalid={isTouched && invalid}
                    errorText={error?.message}
                    onChange={onChange}
                    onBlur={onBlur}
                  />) : (
                  <PatternFormat
                    format={PHONE_FORMAT}
                    mask={MASK_SYMBOL}
                    customInput={InputText}
                    value={value}
                    xVersion
                    absoluteStyles
                    dataTestId="company-details.company-phone-input"
                    isRequired
                    classes="m-b-20 sm-m-b-12"
                    label="Business Phone"
                    isInvalid={isTouched && invalid}
                    isReturnEvent
                    errorText={error?.message}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )
              }
            />
            <InputText
              dataTestId="company-details.address1-input"
              xVersion
              absoluteStyles
              isRequired
              label="Business Address 1"
              name="companyInfo.address1"
              register={register}
              classes="m-b-20 sm-m-b-12"
              isInvalid={!!errors.companyInfo?.address1}
              errorText={errors.companyInfo?.address1?.message}
            />
            <InputText
              dataTestId="company-details.address2-input"
              xVersion
              absoluteStyles
              label="Business Address 2"
              name="companyInfo.address2"
              register={register}
              classes="m-b-20 sm-m-b-12"
              isInvalid={!!errors.companyInfo?.address2}
              errorText={errors.companyInfo?.address2?.message}
            />
            <div className="row-items flex-align-start m-b-20 sm-m-b-12">
              <InputText
                dataTestId="company-details.city-input"
                xVersion
                isRequired
                absoluteStyles
                label="City"
                name="companyInfo.city"
                register={register}
                isInvalid={!!errors.companyInfo?.city}
                errorText={errors.companyInfo?.city?.message}
              />
              {initCountry === CountryCode.Other ? (
                <InputText
                  dataTestId="company-details.postal-code-input"
                  xVersion
                  absoluteStyles
                  isRequired
                  label={POSTAL_CODE_FIELD_DATA[initCountry].label}
                  name="companyInfo.postalCode"
                  register={register}
                  isInvalid={!!(errors.companyInfo?.postalCode && touchedFields.companyInfo?.postalCode)}
                  errorText={errors.companyInfo?.postalCode?.message}
                />
              ) : (
                <Controller
                  name="companyInfo.province"
                  control={control}
                  defaultValue=""
                  shouldUnregister
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { invalid },
                  }) => (
                    <Select
                      dataTestId="company-details.province-select"
                      xVersion
                      absoluteStyles
                      isRequired
                      value={value}
                      label={PROVINCE_FIELD_DATA[initCountry].label}
                      placeholder=""
                      options={PROVINCE_FIELD_DATA[initCountry].options}
                      isInvalid={invalid}
                      isDisabled={isDisablePovinceField}
                      onChange={onChange}
                      onClose={onBlur}
                    />
                  )}
                />
              )}
            </div>
            {initCountry === CountryCode.Other ? (
              <div className="row-items flex-align-start m-b-20 sm-m-b-12">
                {initCountry === CountryCode.Other && (
                  <Controller
                    name="companyInfo.otherCountryCode"
                    control={control}
                    defaultValue=""
                    shouldUnregister
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { invalid },
                    }) => (
                      <Select
                        dataTestId="company-details.country-select"
                        xVersion
                        absoluteStyles
                        isRequired
                        value={value}
                        label="Country"
                        placeholder=""
                        options={otherCountriesSorted.map(({ code, name }) => ({ value: code, label: name }))}
                        isInvalid={invalid}
                        onChange={(value: string): void => {
                          fetchCountryStates(value);
                          onChange(value);
                        }}
                        onClose={onBlur}
                      />
                    )}
                  />
                )}
                <Controller
                  name="companyInfo.province"
                  control={control}
                  defaultValue=""
                  shouldUnregister
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { invalid },
                  }) => (
                    <Select
                      dataTestId="company-details.state-select"
                      xVersion
                      absoluteStyles
                      isRequired
                      value={value}
                      label={PROVINCE_FIELD_DATA[initCountry].label}
                      placeholder=""
                      options={otherCountryStates.map(({ code, name }) => ({ value: code, label: name }))}
                      isInvalid={invalid}
                      isDisabled={isDisablePovinceField}
                      onChange={onChange}
                      onClose={onBlur}
                    />
                  )}
                />
              </div>
            ) : (
              <InputText
                dataTestId="company-details.postal-code-input"
                xVersion
                absoluteStyles
                isRequired
                label={POSTAL_CODE_FIELD_DATA[initCountry].label}
                name="companyInfo.postalCode"
                register={register}
                isInvalid={!!(errors.companyInfo?.postalCode && touchedFields.companyInfo?.postalCode)}
                errorText={errors.companyInfo?.postalCode?.message}
              />
            )}
          </form>
        </div>
        <div className="flex flex-justify-center m-t-40 sm-m-t-32">
          <Button
            dataTestId="company-details.next-step-button"
            xVersion
            absoluteStyles
            isDisabled={!isValid}
            isLoading={isNextLoading}
            onClick={handleSubmit(onNextHandler)}
          >
            Next Step
          </Button>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;