import { FC, useMemo } from 'react';
import './QuickbooksChoose.scss';
import { Button, InputText, Select, StepPriceDetails } from 'components';
import { PatternFormat } from 'react-number-format';
import * as yup from 'yup';

import { Controller, useForm, SubmitHandler } from 'react-hook-form';

import { LICENSE_CODE_FORMAT, LICENSE_CODE_PATTERN, MASK_SYMBOL, PRODUCT_CHARGIFY_TITLES, PRODUCT_CODE_FORMAT, PRODUCT_CODE_PATTERN, QUICKBOOK_OWN_MAP_BY_COUNTRY } from 'consts';
import { CountryCode, ProductChargify, SignUpNavigation } from 'enums';
import { yupResolver } from '@hookform/resolvers/yup';
import { QuickbooksLogo } from 'assets/svg';
import { useAppSelector } from 'store/hooks';
import * as _ from 'lodash';
import { getEnvCountryCode, usePriceHandler } from 'helpers';

interface Props {
  initData?: IQuickbooksForm | null;
  onAdd: (data: IQuickbooksForm) => void;
  currentStepProducts: any;
}

interface IQuickbooksForm {
  name: string;
  quantity: string;
  year?: string;
  licenseCode?: string;
  productCode?: string;
}

const PRODUCT_PRICE_FOR_OWN_OPTION_BY_COUNTRY = {
  [CountryCode.USA]: ProductChargify.QuickBooksEnterpriseDiamond,
  [CountryCode.Canada]: ProductChargify.QuickBooksPro,
  [CountryCode.Other]: ProductChargify.QuickBooksEnterpriseDiamond,
};

const QuickbooksChoose: FC<Props> = ({ onAdd, initData, currentStepProducts }) => {
  const schema = useMemo(() => yup.object({
    name: yup.string().required('Product is required'),
    year: yup.string().required('Year is required'),
    quantity: yup.string()
      .required('Users count is required'),
    licenseCode: yup.string()
      .matches(LICENSE_CODE_PATTERN, { message: 'Enter a valid license code', excludeEmptyString: true }),
    productCode: yup.string()
      .matches(PRODUCT_CODE_PATTERN, { message: 'Enter a valid product code', excludeEmptyString: true }),
  }), []);
  const hostTotalQuantity = useAppSelector(state => state.order.hostProduct.quantity);
  const {
    control,
    getValues,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm<IQuickbooksForm>({ mode: 'all', resolver: yupResolver(schema), defaultValues: initData || { quantity: hostTotalQuantity || '1' } });


  const numbersRange = useMemo(() => _.range(1, +(hostTotalQuantity || 0) + 1).map(el => el.toString()), [hostTotalQuantity]);

  const currentCountryCode = useAppSelector(state => state.order.country?.code ?? CountryCode.USA);
  const envCountryCode = useMemo(() => getEnvCountryCode(currentCountryCode), [currentCountryCode]);
  const { getProductPrice } = usePriceHandler();

  const isDataChanged = useMemo(() => {
    const currentData = getValues();
    return !_.isEqual(currentData, initData);
  }, [watch()]);

  const handleAdd: SubmitHandler<IQuickbooksForm> = (data) => {
    onAdd({ ...data });
  };

  const STEP_PRODUCTS = useMemo(() => {
    const { name, quantity } = getValues();
    return [
      ...(!name ? [] : [{ name, quantity }]),
      ...currentStepProducts.sage,
      ...currentStepProducts.msOwn,
      ...currentStepProducts.msLease,
      ...currentStepProducts.other,
    ];
  }, [currentStepProducts, watch(['quantity', 'name'])]);

  return (
    <>
      <div className="container-sm">
        <div className="sign-up-form-content">
          <div className="sign-up-form-step-heading m-b-8"><QuickbooksLogo className="step-heading-logo"/><div className="text">QuickBooks</div></div>
          <div className="sign-up-form-step-description m-b-32">
            Choose which QuickBooks product you would like installed.
          </div>
          <div className="user-price-wrapper m-b-24">
            <div className="user-price-label">Price per user</div>
            <div className="user-price-count">{getProductPrice(PRODUCT_PRICE_FOR_OWN_OPTION_BY_COUNTRY[envCountryCode])}</div>
          </div>
          <form>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              shouldUnregister
              render={({
                field: { onChange, onBlur, value },
                fieldState: { invalid },
              }) => (
                <Select
                  dataTestId="product-list.quickbooks.name-select"
                  xVersion
                  absoluteStyles
                  value={value}
                  classes="m-b-20 sm-m-b-12"
                  options={QUICKBOOK_OWN_MAP_BY_COUNTRY[envCountryCode].map(
                    el => ({ label: PRODUCT_CHARGIFY_TITLES[el.name], value: el.name }),
                  )}
                  label="Select QuickBooks Product"
                  placeholder=""
                  isRequired
                  isInvalid={invalid}
                  onChange={onChange}
                  onClose={onBlur}
                />
              )}
            />
            <div className="row-items flex-align-start m-b-20 sm-m-b-12">
              <Controller
                name="year"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                shouldUnregister
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { invalid },
                }) => (
                  <Select
                    dataTestId="product-list.quickbooks.edition-select"
                    xVersion
                    absoluteStyles
                    value={value}
                    label="QuickBooks Edition"
                    placeholder=""
                    options={[
                      '2024',
                      '2023',
                      '2022',
                      '2021',
                      '2020',
                      '2019',
                      '2018',
                      '2017',
                      '2016',
                      '2015',
                    ]}
                    isInvalid={invalid}
                    isRequired
                    onChange={onChange}
                    onClose={onBlur}
                  />
                )}
              />
              <Controller
                name="quantity"
                control={control}
                shouldUnregister
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { invalid },
                }) => (
                  <Select
                    dataTestId="product-list.quickbooks.quantity-select"
                    xVersion
                    absoluteStyles
                    value={value}
                    label="Number of Users"
                    placeholder=""
                    options={numbersRange}
                    isInvalid={invalid}
                    isRequired
                    onChange={onChange}
                    onClose={onBlur}
                  />
                  // <PatternFormatat
                  //   allowNegative={false}
                  //   customInput={InputText}
                  //   xVersion
                  //   absoluteStyles
                  //   isRequired
                  //   label="Number of users"
                  //   placeholder=""
                  //   value={value}
                  //   isInvalid={isTouched && invalid}
                  //   errorText={error?.message}
                  //   isReturnEvent
                  //   onChange={onChange}
                  //   onBlur={onBlur}
                  // />
                )}
              />
            </div>
            <Controller
              name="licenseCode"
              control={control}
              defaultValue=""
              rules={{ required: false, pattern: LICENSE_CODE_PATTERN }}
              shouldUnregister
              render={({
                field: { onChange, onBlur, value },
                fieldState: { invalid, isTouched, error },
              }) => (
                <PatternFormat
                  format={LICENSE_CODE_FORMAT}
                  mask={MASK_SYMBOL}
                  customInput={InputText}
                  dataTestId="product-list.quickbooks.license-code-input"
                  classes="m-b-20 sm-m-b-12"
                  xVersion
                  absoluteStyles
                  label="License Code"
                  placeholder=""
                  value={value}
                  isInvalid={isTouched && invalid}
                  errorText={error?.message}
                  isReturnEvent
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              name="productCode"
              control={control}
              defaultValue=""
              rules={{ required: false, pattern: PRODUCT_CODE_PATTERN }}
              shouldUnregister
              render={({
                field: { onChange, onBlur, value },
                fieldState: { invalid, isTouched, error },
              }) => (
                <PatternFormat
                  format={PRODUCT_CODE_FORMAT}
                  mask={MASK_SYMBOL}
                  customInput={InputText}
                  classes="m-b-20 sm-m-b-12"
                  dataTestId="product-list.quickbooks.product-code-input"
                  label="Product Code"
                  xVersion
                  absoluteStyles
                  placeholder=""
                  value={value}
                  isInvalid={isTouched && invalid}
                  errorText={error?.message}
                  isReturnEvent
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </form>
          <div className="flex flex-justify-center m-t-40 sm-m-t-32">
            <Button
              dataTestId="product-list.quickbooks.add-edit-button"
              xVersion
              absoluteStyles
              isDisabled={!isValid || !isDataChanged}
              onClick={handleSubmit(handleAdd)}
            >
              {initData ? 'Edit Application' : 'Add Application'}
            </Button>
          </div>
        </div>
      </div>
      <StepPriceDetails currentStep={SignUpNavigation.ProductsList} stepProducts={STEP_PRODUCTS} />
    </>
  );
};

export default QuickbooksChoose;